import React, {useState} from 'react';
import ThreeD from './ThreeDTop';
import Intro from './Intro';
import Main from './Main';
import './App.scss';

function App() {
  let name = window.NT_NAME;
 
  if(typeof(name) === "undefined") {
    name = null;
  }

  const [intro, setIntro] = useState(name === null);

  return intro ? (
    <>
      <Intro />
      <ThreeD />
    </>
  ) : <Main />;
}

export default App;
