import React from 'react';

export default function Cookies() {

    let message = 'Nike uses first party cookies that are necessary for our website to function effectively.';

    switch(window.country) {
        case 'AT':
            message = 'Nike verwendet Cookies von Erstanbietern, die für ein effektives Funktionieren unserer Website erforderlich sind.';
        break;
        case 'BE':
            message = 'Nike maakt gebruikt van cookies die noodzakelijk zijn voor de goede werking van onze website.';
        break;
        case 'BG':
            message = 'Nike използва бисквитки, които са необходими за ефективното функциониране на уебсайта ни.';
        break;
        case 'HR':
            message = 'Nike koristi kolačiće prve strane koji su nužni za normalno funkcioniranje mrežne stranice.';
        break;
        case 'CZ':
            message = 'Nike používá cookies první strany, které jsou nezbytné pro efektivní fungování našich webových stránek.';
        break;
        case 'DK':
            message = 'Nike anvender førstepartscookies, som er nødvendige for, at vores hjemmeside kan fungere korrekt.';
        break;
        case 'EE':
            message = 'Nike kasutab esimese osapoole küpsiseid, mis on vajalikud meie veebisaidi toimimiseks.';
        break;
        case 'FI':
            message = 'Nike käyttää sivuillaan evästeitä, jotka ovat välttämättömiä sivujen toiminnalle.';
        break;
        case 'FR':
            message = 'Nike utilise des cookies de base, nécessaires au bon fonctionnement de notre site internet.';
        break;
        case 'DE':
            message = 'Nike verwendet Cookies von Erstanbietern, die für ein effektives Funktionieren unserer Website erforderlich sind.';
        break;
        case 'GR':
            message = 'Η Nike χρησιμοποιεί cookies πρώτου μέρους τα οποία είναι αναγκαία για την αποτελεσματική λειτουργία της ιστοσελίδας μας.';
        break;
        case 'HU':
            message = 'A Nike elsődleges sütiket használ, amelyek a weboldalunk hatékony működéséhez szükségesek.';
        break;
        case 'IE':
            message = "Baineann Nike úsáid as fianáin láithreáin ghreasáin chéad pháirtí atá riachtanach d'fheidhmiú éifeachtach ár suíomh gréasáin.";
        break;
        case 'IT':
            message = 'Nike utilizza cookie proprietari necessari al corretto funzionamento del nostro sito web.';
        break;
        case 'NL':
            message = 'Nike gebruikt first-party cookies die nodig zijn om onze website effectief te laten functioneren';
        break;
        case 'LV':
            message = 'Nike izmanto pirmās puses sīkfailus, kas nepieciešami šīs vietnes efektīvai darbībai.';
        break;
        case 'LB':
            message = 'Nike naudoja pirmosios šalies slapukus, kurie reikalingi siekiant užtikrinti efektyvų mūsų svetainės veikimą.';
        break;
        case 'LU':
            message = 'Nike verwendet Erstanbieter-Cookies, die erforderlich sind, damit unsere Website effektiv funktioniert.';
        break;
        case 'MT':
            message = "Nike tuża cookies tal-ewwel parti li huma neċessarji sabiex is-sit tagħna jaħdem b'mod effettiv.";
        break;
        case 'PL':
            message = 'Nike wykorzystuje własne pliki cookies w celu zapewnienia skutecznego funkcjonowania strony.';
        break;
        case 'PT':
            message = 'A Nike utiliza cookies de primeira parte que são necessários para que nosso site funcione efetivamente.';
        break;
        case 'RO':
            message = 'Nike utilizează module cookie proprii care sunt necesare pentru ca website-ul nostru să funcționeze în mod eficient.';
        break;
        case 'SK':
            message = 'Nike používá soubory cookies první strany, které jsou nezbytné pro efektivní fungování našeho webu.';
        break;
        case 'SI':
            message = 'Nike uporablja lastne piškotke, ki so nujni za učinkovito delovanje naše spletne strani.';
        break;
        case 'ES':
            message = 'Nike utiliza cookies propias que son necesarios para que nuestra página web funcione de manera efectiva.';
        break;
        case 'SE':
            message = 'Nike använder förstapartscookies som är nödvändiga för att vår webbsida ska fungera effektivt.';
        break;
        case 'GB':
            message = 'Nike uses first-party cookies that are necessary for our website to function effectively.';
        break;
        case 'CH':
            message = 'Nike verwendet Erstanbieter-Cookies, die erforderlich sind, damit unsere Website effektiv funktioniert.';
        break;
        case 'CY':
            message = 'Nike, internet sayfamızın işlevlerini etkin bir şekilde yerine getirmesi için gerekli olan birinci taraf çerezleri kullanmaktadır.';
        break;
        case 'NO':
            message = 'Nike bruker førsteparts-cookies, noe som er nødvendig for at nettsiden vår skal fungere effektivt.';
        break;
        default: 
            message = 'Nike uses first party cookies that are necessary for our website to function effectively.';
    }

    return(
        <div className="cookies">{message}</div>
    )
}