import React, {useRef, useState, useEffect} from 'react';
import Container from './Container';
import Player from '@vimeo/player';
//import Vimeo from '@u-wave/react-vimeo';
import { useWindowSize } from '@react-hook/window-size'

import ball from './img/ball.png';
//import mute from './img/mute.svg';
//import unmute from './img/unmute.svg';

export default function Main() {
    const video = useRef();
    const player = useRef();
    const $videoHolder = useRef();
    //const [muted, setMuted] = useState(false);
    const [width, height] = useWindowSize()
    const [start, setStart] = useState(false);

    const name = window.NT_NAME || '';
    
    const playVideo = () => {
        //video.current.player.play();
        player.current.play();
    };

    const go = () => {
        setStart(true);
        playVideo();
    }

    useEffect(() => {
        const options = {
            id: width > height ? "453735294" : "453756185",
            width: width,
            height: height,
            loop: true,
            controls: false,
            dnt: window.country === 'US' || window.country === 'MX' || window.country === 'CA' ? false : true
        };

        player.current = new Player($videoHolder.current, options);

        player.current.on('loaded', () => {
            const frame = $videoHolder.current.querySelector('iframe');
            if (frame !== null) {
                frame.style.width = '100%'
                frame.style.height = '100%'
            }
        });
    }, []);

    return (
        <>
            <div className={start ? "main" : "main blur"}>
                <div ref={$videoHolder} className="video-holder"></div>
                {/*<Vimeo
                    ref={video}
                    className="video-holder"
                    video={width > height ? "453735294" : "453756185"}
                    width={width}
                    height={height}
                    //muted={true}
                    volume={1}
                    loop
                    dnt={1}
                    controls={false}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1
                    }}
                    />*/}
                <div className="main-name nike">{name}</div>
                <div className="main-tennis-ball"><img src={ball} alt="Tennis Ball" /></div>

                {/*<div className="main-mute">
                    <button onClick={() => setMuted(s => !s)}>{muted ? <img src={mute} alt="Mute" /> : <img src={unmute} alt="Unmute" />}</button>
                </div>*/}

                <Container name={name} start={start} />
            </div>
            {!start ? <div className="interstitial">
                <div className="intro">
                    <div className="intro-inner">
                            <div className="int-pad nike">Welcome to open court! The event will begin shortly. In the meantime, please explore the site.</div>
                            <button className="nike" onClick={go}>Enter</button>
                        </div>
                </div>
            </div> : null}
        </>
    );
}