import React from 'react';

import arrow from './img/arrow-right.svg';
import bg1 from './img/play1.jpg';
import bg2 from './img/play2.jpg';
import bg3 from './img/play3.jpg';
import bg4 from './img/play4.jpg';
import bg5 from './img/play5.jpg';

export default function PlaySection() {
    return (
        <div className="content-section-wide clearfix">
            <a className="play-bg nike" href='https://www.nike.com/w/challenge-court-collection-49a0l' target="_blank">
                <div className="play-bg-child" style={{backgroundImage: 'url('+bg1+')'}}></div>
                <div className="play-bg-text">
                    <div>
                        Shop Nike Court Collection
                    </div>
                </div>    
            </a>

            <a className="play-bg nike" href="https://www.nike.com/ntc-app" target="_blank">
                <div className="play-bg-child" style={{backgroundImage: 'url('+bg2+')'}}></div>
                <div className="play-bg-text">
                    <div>
                        Try NTC Premium For Free
                    </div>
                </div>    
            </a>

            <a className="play-bg nike" href="https://www.youtube.com/playlist?list=PLNqKTn4CuEXdcqbJINlNNsFmhMQRBFcsV" target="_blank">
                <div className="play-bg-child" style={{backgroundImage: 'url('+bg3+')'}}></div>
                <div className="play-bg-text">
                    <div>
                        Stay active with NTC Community Workouts
                    </div>
                </div>    
            </a>

            <a className="play-bg nike" href="https://www.nike.com/journal/coaching" target="_blank">
                <div className="play-bg-child" style={{backgroundImage: 'url('+bg4+')'}}></div>
                <div className="play-bg-text">
                    <div>
                        Set goals with Nike Journal
                    </div>
                </div>    
            </a>

            <a className="play-bg nike" href="https://www.nycgovparks.org/facilities/tennis" target="_blank">
                <div className="play-bg-child" style={{backgroundImage: 'url('+bg5+')'}}></div>
                <div className="play-bg-text">
                    <div>
                        Find tennis courts in NYC
                    </div>
                </div>    
            </a>
            
        </div>
    );
}