import * as THREE from 'three'
import React, { Suspense, useMemo, useRef, useCallback, useState, useEffect } from 'react'
import { Canvas, useFrame, useLoader, useThree } from 'react-three-fiber'
import { Physics, useBox, usePlane, useSphere, useCompoundBody, useCylinder } from 'use-cannon'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'drei';
import tennisTexture from './img/tennis-texture-nike-2.png';


function Swoosh({envMap}) {
    //const ref = useRef();
    const { viewport } = useThree()
    const [ref] = useCompoundBody(() => ({ 
        mass: 1, 
        //fixedRotation: true, 
        sleepSpeedLimit: 0.5,
        allowSleep: true,
        position: [0, 0, 0],
        shapes: [
            { 
                type: 'Box',
                position: [-3.3,-0.16,-0.2],
                args: [1,2.6,0.7]
            },
            { 
                type: 'Box',
                position: [-2.3,-0.8,-0.2],
                args: [1,1.2,0.7]
            },
            { 
                type: 'Box',
                position: [1,0.15,-0.2],
                rotation: [0,0,0.27],
                args: [6,0.8,0.7]
            }
        ]
    }));

    const { nodes } = useLoader(GLTFLoader, process.env.PUBLIC_URL + '/3d/swoosh_rounded.gltf')

    return (
        <group ref={ref}>
            <mesh scale={[0.05,0.05,0.05]} geometry={nodes.SWOOSH.geometry} receiveShadow castShadow>
                <meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  />
            </mesh>
            
            {/*<mesh position={[-3.3,-0.16,-0.2]}>
                <boxBufferGeometry attach="geometry" args={[1,2.6,0.7]} />
                <meshStandardMaterial attach="material" />
            </mesh>

            <mesh position={[-2.3,-0.8,-0.2]}>
                <boxBufferGeometry attach="geometry" args={[1,1.2,0.7]} />
                <meshStandardMaterial attach="material" />
            </mesh>

            <mesh position={[1,0.15,-0.2]} rotation={[0,0,0.27]}>
                <boxBufferGeometry attach="geometry" args={[6,0.8,0.7]} />
                <meshStandardMaterial attach="material" />
            </mesh>*/}
        </group>
       
    )
}

function Court({envMap}) {
  //const ref = useRef()

  const [ref] = useCompoundBody(() => ({ 
      mass: 1, 
      //fixedRotation: true, 
      sleepSpeedLimit: 0.5,
      allowSleep: true,
      position: [0, 0, 0],
      shapes: [
          { 
              type: 'Box',
              position: [0,0,0.3],
              args: [10,5,0.4]
          },
          { 
              type: 'Box',
              position: [0,0,0.7],
              args: [0.3,4.5,0.8]
          }
      ]
  }));

  const { nodes, materials } = useLoader(GLTFLoader, '/3d/court.gltf');
  const [texture] = useLoader(THREE.TextureLoader, ['/3d/court1alpha.png']);

  

  return (
    <group ref={ref}>
      <group dispose={null} scale={[0.05,0.05,0.05]} rotation={[Math.PI / 2, 0, 0 ]}>
        <mesh geometry={nodes.mesh_0_0.geometry} receiveShadow castShadow>
          <meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  />
        </mesh>
        <mesh geometry={nodes.mesh_0_1.geometry} receiveShadow castShadow>
          <meshStandardMaterial attach="material" color={'white'} roughness={0.1} bumpMap={texture} bumpScale={0.5} envMap={envMap} metalness={1}  />
        </mesh>
      </group>

      {/*<mesh position={[0,0,0.3]}>
          <boxBufferGeometry attach="geometry" args={[10,5,0.4]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[0,0,0.7]}>
          <boxBufferGeometry attach="geometry" args={[0.3,4.5,0.8]} />
          <meshStandardMaterial attach="material" />
      </mesh>*/}
    </group>
  )
}

function Plane({ color, size= [500, 500], position= [0,0,0], rotation = [0,0,0], ...props }) {
  const [ref] = usePlane(() => ({ position, rotation }))
  return (
    <mesh ref={ref}>
      <planeBufferGeometry attach="geometry" args={size} />
      <meshPhongMaterial attach="material" transparent opacity={0} />
    </mesh>
  )
}

function Box({mouse}) {
  //const ref = useRef();
  const boxSize = window.innerWidth < 768 ? [4,4,4] : [2, 2, 2];
  const [ref, api] = useBox(() => ({ mass: 1, args: boxSize, isKinematic: true }));
  const { size, viewport } = useThree()
  const aspect = size.width / viewport.width;

  useFrame(state => {
    const t = state.clock.getElapsedTime()
    api.position.set(Math.sin(t * 2) * 5, Math.cos(t * 2) * 5, 3)
    api.rotation.set(Math.sin(t * 6), Math.cos(t * 6), 0)
    api.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 0)
  })
  return (
    <mesh ref={ref}>
      <boxBufferGeometry attach="geometry" args={boxSize} />
      <meshPhongMaterial attach="material" transparent={true} opacity={0} />
    </mesh>
  )
}


function CantStop({envMap}) {
  //const ref = useRef()
  const [ref] = useCompoundBody(() => ({ 
      mass: 1, 
      sleepSpeedLimit: 0.5,
      allowSleep: true,
      position: [0, 0, 0],
      shapes: [
        { type: 'Box', position: [0,2.8,0.5], args: [3,0.2,1], rotation: [0,0,0] },
        { type: 'Box', position: [0,-3.2,0.5], args: [2.8, 0.2,1], rotation: [0,0,0] },
        { type: 'Box', position: [3.3,0,0.5], args: [0.2,3 , 1], rotation: [0,0,0] },
        { type: 'Box', position: [-2.8,0,0.5], args: [0.2,3, 1], rotation: [0,0,0] },
        { type: 'Box', position: [-2.1,-2.2,0.5], args: [0.2,2, 1], rotation: [0,0,Math.PI / 4] },
        { type: 'Box', position: [2.5,-2.1,0.5], args: [0.2,3, 1], rotation: [0,0,-Math.PI / 4] },
        { type: 'Box', position: [2.2,2.2,0.5], args: [0.2,2.7, 1], rotation: [0,0,Math.PI / 4] },
        { type: 'Box', position: [-1.8,2.2,0.5], args: [0.2,2.7, 1], rotation: [0,0,-Math.PI / 4] },
      ]
  }));
  const { nodes } = useLoader(GLTFLoader, '/3d/cant_stop2.gltf')
  return (
    <group ref={ref} dispose={null}>
      <group position={[0.95,-0.5,0]} scale={[0.035,0.035,0.035]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Y.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.O.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.U.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.C.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.A.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.N.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.nn.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.T.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.S.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.T_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.O_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.P.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.S_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.P_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.O_2.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.R.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.T_2.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.Y_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.O_3.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.U_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.C_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.A_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.N_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes._1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.T_3.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.S_2.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.T_4.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.O_4.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.P_2.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.S_3.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.P_3.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.O_5.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.R_1.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
        <mesh geometry={nodes.T_5.geometry}><meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  /></mesh>
      </group>
    
      {/*<mesh position={[0,2.8,0.5]}>
          <boxBufferGeometry attach="geometry" args={[3,0.2,1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[0,-3.2,0.5]}>
          <boxBufferGeometry attach="geometry" args={[2.8,0.2,1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[3.3,0,0.5]}>
          <boxBufferGeometry attach="geometry" args={[0.2,3, 1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[-2.8,0,0.5]}>
          <boxBufferGeometry attach="geometry" args={[0.2,3, 1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[-2.1,-2.2,0.5]} rotation={[0,0,Math.PI / 4]}>
          <boxBufferGeometry attach="geometry" args={[0.2,2, 1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[2.5,-2.1,0.5]} rotation={[0,0,-Math.PI / 4]}>
          <boxBufferGeometry attach="geometry" args={[0.2,3, 1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[2.2,2.2,0.5]} rotation={[0,0,Math.PI / 4]}>
          <boxBufferGeometry attach="geometry" args={[0.2,2.7, 1]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[-1.8,2.2,0.5]} rotation={[0,0,-Math.PI / 4]}>
          <boxBufferGeometry attach="geometry" args={[0.2,2.7, 1]} />
          <meshStandardMaterial attach="material" />
      </mesh>*/}

    </group>
  )
}

function InstancedSpheres({ number = 100 }) {
  const map = useLoader(THREE.TextureLoader, tennisTexture)
  const [ref] = useSphere(index => ({
    mass: 1,
    position: [Math.random(), Math.random(), Math.random()],
    rotation: [Math.PI * Math.random(), Math.PI * Math.random(), Math.PI * Math.random()],
    args: 1,
    sleepSpeedLimit: 0.5,
    allowSleep: true,
  }))
  return (
    <instancedMesh ref={ref} castShadow receiveShadow args={[null, null, number]}>
      <sphereBufferGeometry attach="geometry" args={[1, 32, 16]} />
      <meshStandardMaterial
        attach="material"
        map={map}
      />
    </instancedMesh>
  )
}


function Planes() {
    const wall = useRef();
    const { camera, viewport/*, size: {width,height} */} = useThree();

    /*const { width, height } = useMemo(
        () =>
        aspect > 1
            ? {
                width: viewport.width,
                height: viewport.height / aspect
            }
            : {
                width: aspect,
                height: viewport.height
            },

        [aspect, viewport]
    );*/


    return(
        <>
            <Plane color={'red'} position={[0, 0, -2]}  />
            <Plane color={'green'} position={[-viewport.width / 2, 0, 0]} rotation={[0, Math.PI / 2, 0]} />
            <Plane color={'green'} position={[viewport.width / 2, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
            <Plane color={'blue'} position={[0, 0, 2]} rotation={[0, Math.PI, 0]} />

            {/* ceiling and floor */}
            <Plane color={'yellow'} position={[0, viewport.height / 2, 0]} rotation={[Math.PI / 2, 0, 0]} />
            <Plane color={'yellow'} position={[0, -viewport.height / 2, 0]} rotation={[-Math.PI / 2, 0, 0]} />
        </>
    );
}

function Serena({envMap}) {
  //const ref = useRef()
  const [ref] = useCompoundBody(() => ({ 
    mass: 1, 
    //fixedRotation: true, 
    sleepSpeedLimit: 0.5,
    allowSleep: true,
    position: [0, 0, 0],
    shapes: [
        { 
            type: 'Box',
            position: [0,0,-0.2],
            args: [3,5.7,0.6]
        },
        { 
            type: 'Box',
            position: [-1.8,1.7,-0.2],
            args: [0.8,3.5,0.6]
        },
        { 
            type: 'Box',
            position: [1.8,1.7,-0.2],
            args: [0.8,3.5,0.6]
        },
        { 
            type: 'Box',
            position: [0,-3.1,-0.2],
            args: [1,1,0.6]
        }
    ]
}));

  const { nodes } = useLoader(GLTFLoader, '/3d/serena.gltf')
  return (
    <group ref={ref} dispose={null}>
      <mesh geometry={nodes.SERENA_LOGO.geometry} scale={[0.05,0.05,0.05]} receiveShadow castShadow>
        <meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  />
      </mesh>

      {/*<mesh position={[0,0,-0.2]}>
          <boxBufferGeometry attach="geometry" args={[3,5.7,0.6]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[-1.8,1.7,-0.2]}>
          <boxBufferGeometry attach="geometry" args={[0.8,3.5,0.6]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[1.8,1.7,-0.2]}>
          <boxBufferGeometry attach="geometry" args={[0.8,3.5,0.6]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[0,-3.1,-0.2]}>
          <boxBufferGeometry attach="geometry" args={[1,1,0.6]} />
          <meshStandardMaterial attach="material" />
      </mesh>*/}
    </group>
  )
}

function Court2({envMap}) {
  //const ref = useRef()
  const [ref, api] = useBox(() => ({ mass: 1, args: [4.3,6.5,1], isKinematic: true }));
  const { nodes } = useLoader(GLTFLoader, '/3d/nikecourt2.gltf')
  return (
    <group ref={ref} dispose={null}>
      <group>
        <mesh geometry={nodes.Extrude1.geometry} scale={[0.0008,0.0008,0.0008]} receiveShadow castShadow>
          <meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  />
        </mesh>

        {/*<mesh position={[0,0,0]}>
          <boxBufferGeometry attach="geometry" args={[4.3,6.5,1]} />
          <meshStandardMaterial attach="material" />
      </mesh>*/}
      </group>
    </group>
  )
}


function Racquet({envMap}) {
  //const ref = useRef();
  const [ref] = useCompoundBody(() => ({ 
    mass: 1, 
    //fixedRotation: true, 
    sleepSpeedLimit: 0.5,
    allowSleep: true,
    position: [0, 0, 0],
    shapes: [
        { 
            type: 'Box',
            position: [0,-2.5,0],
            args: [0.5,3,0.5]
        },
        { 
            type: 'Box',
            position: [0,0,0],
            rotation: [0,0,Math.PI / 4],
            args: [1.7,1.7,0.5]
        },
        { 
            type: 'Box',
            position: [-0.1,2,0],
            args: [3.1,4,0.5]
        }
    ]
  }));

  const { nodes } = useLoader(GLTFLoader, '/3d/racquet2.gltf')
  return (
    <group ref={ref} dispose={null}>
      <mesh geometry={nodes.Tennis_Racket_Null_Tube_14.geometry} scale={[0.01,0.01,0.01]} position={[0,-4,0]} receiveShadow castShadow>
        <meshStandardMaterial attach="material" color={'white'} roughness={0.1} envMap={envMap} metalness={1}  />
      </mesh>

      {/*<mesh position={[0,-2.5,0]}>
          <boxBufferGeometry attach="geometry" args={[0.5,3,0.5]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[0,0,0]} rotation={[0,0,Math.PI / 4]}>
          <boxBufferGeometry attach="geometry" args={[1.7,1.7,0.5]} />
          <meshStandardMaterial attach="material" />
      </mesh>

      <mesh position={[-0.1,2,0]}>
          <boxBufferGeometry attach="geometry" args={[3.1,4,0.5]} />
          <meshStandardMaterial attach="material" />
      </mesh>*/}
    </group>
  )
}



function CustomObjects() {
  const [envMap] = useLoader(THREE.CubeTextureLoader, [
    [
      process.env.PUBLIC_URL + '/3d/environment2.jpg',
      process.env.PUBLIC_URL + '/3d/environment2.jpg',
      process.env.PUBLIC_URL + '/3d/environment2.jpg',
      process.env.PUBLIC_URL + '/3d/environment2.jpg',
      process.env.PUBLIC_URL + '/3d/environment2.jpg',
      process.env.PUBLIC_URL + '/3d/environment2.jpg'
    ]
  ]);

  return (
    <>
      
      <CantStop envMap={envMap} />
      <Racquet envMap={envMap} />
      <Court2 envMap={envMap} />
      <Serena envMap={envMap} />
      <Swoosh envMap={envMap} />
      <Racquet envMap={envMap} />
      <InstancedSpheres number={25} />
    </>
  );
}

export default function ThreeD() {
    const mouse = useRef([0, 0])
    const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {if(x) { (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2])}}, [])
    const [down, setMouseDown] = useState(false);

    const { aspect, viewport } = useThree();

    const zoom = window.innerWidth < 500 ? 20 : 13;
    return(
        <Canvas 
            onMouseDown={(e) => {onMouseMove(e); setMouseDown(true);}}
            onMouseUp={() => setMouseDown(false)}
            onTouchStart={(e) => { onMouseMove(e.touches[0]); setMouseDown(true);}}
            onTouchEnd={() => setMouseDown(false)}
            onMouseMove={onMouseMove} 
            onTouchMove={(e) => onMouseMove(e.touches[0])} 
            gl={{
                powerPreference: "high-performance",
                alpha: true,
            }} 
            concurrent 
            shadowMap 
            sRGB 
            colorManagement 
            camera={{ position: [0, 0, zoom], fov: 55, near: 0.1,}}
        >
            <ambientLight intensity={0.35} />
            <spotLight position={[30, 0, 30]} angle={0.3} penumbra={1} intensity={0.5} castShadow shadow-mapSize-width={256} shadow-mapSize-height={256} />
            <Physics allowSleep gravity={[0, 0, 0]}>
                <Planes />
                
                {window.innerWidth < 768 ? 
                  (down ? <Box mouse={mouse} /> : null) : <Box mouse={mouse} />}
                <Suspense fallback={null}>
                  <CustomObjects />
                </Suspense>
            </Physics>
             {/*<Suspense fallback={null}>
                <CustomObjects />
            </Suspense>
            <OrbitControls />*/}
        </Canvas>
    )
}