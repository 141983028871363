import React, {useState} from 'react';

const posts = [
    {
        title: 'America Scores NY',
        description: 'America Scores NY provides services for youth through a combination of soccer, poetry, and service learning.',
        link: 'https://www.newyorkscores.org/',
        image: process.env.PUBLIC_URL + '/connect/1_america-scores.png'
    },
    {
        title: "Children's Aid",
        description: "Children's Aid is an independent, nonprofit organization established to serve the children, youth, and families in several under-resourced New York City neighborhoods.",
        link: 'https://www.childrensaidnyc.org/',
        image: process.env.PUBLIC_URL + '/connect/2_childrens-aid.png'
    },
    {
        title: "Figure Skating in Harlem",
        description: "Figure Skating in Harlem helps girls transform their lives and grow in confidence, leadership, and academic achievement.",
        link: 'https://figureskatinginharlem.org/',
        image: process.env.PUBLIC_URL + '/connect/3_figure-skating-of-harlem.png'
    },
    {
        title: "FitSpirit",
        description: "FitSpirit (Fillactive in French) is a Quebec-based not-for-profit whose mission is to get teenage girls to be physically active throughout their lives.",
        link: 'https://www.fitspirit.ca/',
        image: process.env.PUBLIC_URL + '/connect/4_fitspirit.png'
    },
    {
        title: "Girls in the Game",
        description: "Girls in the Game helps every girl find her voice, discover her strength and lead with confidence through sports, health, and leadership programs.",
        link: 'https://www.girlsinthegame.org/',
        image: process.env.PUBLIC_URL + '/connect/5_girls-in-the-game.png'
    },
    {
        title: "Kings County Tennis League",
        description: "Kings County Tennis League (KCTL) combines tennis and education to spark the potential of children living in and around Brooklyn public housing.",
        link: 'https://www.kingscountytennisleague.org/',
        image: process.env.PUBLIC_URL + '/connect/6_kings-county-tennis-league.png'
    },
    {
        title: "Lay-Up",
        description: "Lay-Up is a cost-free program centered on the culture of basketball designed to develop the skills kids need on and off the court.",
        link: 'https://www.layup.ca/',
        image: process.env.PUBLIC_URL + '/connect/7_layup.png'
    },
    {
        title: "Lower East Side Girls Club",
        description: "The Lower East Side Girls Club connects girls and young women to healthy, successful futures.",
        link: 'https://www.girlsclub.org/',
        image: process.env.PUBLIC_URL + '/connect/8_les.png'
    },
    {
        title: "NYC Department of Youth and Community Development (DYCD)",
        description: "The New York City Department of Youth and Community Development (DYCD) invests in a network of community-based organizations and programs to alleviate the effects of poverty and to provide opportunities for New Yorkers and communities to flourish.",
        link: 'https://www1.nyc.gov/site/dycd/about/about-dycd/about-dycd.page',
        image: process.env.PUBLIC_URL + '/connect/9_nyc.png'
    },
    {
        title: "NYC Junior Tennis League",
        description: "The mission of New York Junior Tennis & Learning is to develop the character of young people through tennis and education for a lifetime of success, on and off the court.",
        link: 'https://www.nyjtl.org/',
        image: process.env.PUBLIC_URL + '/connect/10_nyc-junior-tennis-league.png'
    },
    {
        title: "PeacePlayers",
        description: "At PeacePlayers, we use the power of sport to unite, educate, and inspire young people to create a more peaceful world. We offer sport programming, peace education, and leadership development to those living in communities in conflict.",
        link: 'https://www.peaceplayers.org/about-ppi/',
        image: process.env.PUBLIC_URL + '/connect/11_peace-players.png'
    },
    {
        title: "PowerPlay NYC",
        description: "PowerPlay NYC advances the lives of girls through sports, helping them grow physically, emotionally, and academically stronger.",
        link: 'https://www.powerplaynyc.org/',
        image: process.env.PUBLIC_URL + '/connect/12_powerplay-nyc.png'
    },
    {
        title: "Special Aces",
        description: "Special Aces, located at the Prospect Park Tennis Center offers group tennis instruction for children with special needs. ",
        link: 'https://www.prospectpark.org/visit-the-park/places-to-go/tennis-center/youth-programs/special-aces/',
        image: process.env.PUBLIC_URL + '/connect/13_special-aces.png'
    },
];

export default function Connect() {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            <div className="content-section-wide">
                { 
                    posts.map((post, p) => {
                        return (
                            <a href={post.link} key={p} target="_blank" rel="noopener noreferrer" className="connect-post" style={loaded || p < 4 ? null : {display: 'none'}}>
                                <div className="connect-post-left">
                                    <div className="connect-post-left-img">
                                        <div className="connect-post-left-img-inner"><img src={post.image} alt={post.title} /></div>
                                    </div>
                                    <div className="connect-post-left-text">
                                        <div className="connect-post-title">{post.title}</div>
                                        <div className="connect-post-description">{post.description}</div>
                                    </div>
                                </div>
                            </a>
                        );
                    }) 
                }
            </div>
            {loaded ? null : <button className="load-more nike" onClick={()=>setLoaded(true)}>Load More</button>}
        </>
    );
}