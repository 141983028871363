import React, {useRef, useState, useEffect} from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import { fabric } from "fabric";
import { useWindowSize } from '@react-hook/window-size'
import FileSaver from 'file-saver';
import axios from 'axios';
//import FileSaver from './FileSaver';
import './canvas-to-blob.js';

import frontImage from './img/creator-text.png';
import bgImageWhite from './img/creator-background-white.png';
import bgImageGreen from './img/creator-background-green.png';
import bgImageClouds from './img/creator-background-clouds.jpg';

import creatorSwoosh from './img/creator-swoosh.png';
import creatorSerena from './img/creator-serena.png';
import creatorRacquet from './img/creator-racquet.png';
import creatorBall from './img/creator-ball.png';
import creatorCourt from './img/creator-court.png';
import creatorYCSS from './img/creator-ycss.png';

export default function Create({name = 'Jane Doe'}) {
    const [width, height] = useWindowSize()
    const detectMobile = useMobileDetect();
    const canvas = useRef(null);
    const f = useRef(null);
    const bgImage = useRef();
    const [showDelete, setDelete] = useState(false);
    const [downloadMobile, setDownloadMobile] = useState(null);

    const nikeText = useRef();
    const userText = useRef();

    const WIDTH = 540;//mobile ? window.innerWidth : 600;
    const HEIGHT = 675; //mobile ? window.innerWidth : 600;

    const setupCanvas = () => {
        fabric.Image.fromURL(bgImageWhite, o => {
            /*o.set({
                id: 'bg1',
                hasBorders: false,
                hasControls: false,
                hasRotatingPoint: false,
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                selectable: false,
                defaultCursor: 'pointer',
                evented: false
            });
            o.scaleToHeight(HEIGHT);
            o.scaleToWidth(WIDTH);
            bgImage.current = o;

            f.current.add(bgImage.current);*/

            f.current.setBackgroundImage(o);

            fabric.Image.fromURL(frontImage, front => {
                front.set({
                    id: 'front',
                    hasBorders: false,
                    hasControls: false,
                    hasRotatingPoint: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    selectable: false,
                    evented: false
                });
                front.scaleToHeight(HEIGHT);
                front.scaleToWidth(WIDTH);
                nikeText.current = front;

                f.current.add(nikeText.current);

                var text = new fabric.Text(name, { 
                    left: WIDTH / 2, 
                    top: HEIGHT - 215,
                    fontFamily: 'WomensTennisSSStrial',
                    fill: '#FF00AA',
                    fontSize: 96,
                    angle: -3,
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    evented: false,
                    opacity: 0.9
                });
                userText.current = text;
                f.current.add(userText.current);
            }); 
        });
    };

    const deleteImage = () => {
        let currentSelection = f.current.getActiveObject();
        if(currentSelection) {
            f.current.remove(currentSelection);
            f.current.renderAll();
        }
    };

    const addImage = (url) => {
        f.current.discardActiveObject();
        
        fabric.Image.fromURL(url, o => {
            o.set({
                left: WIDTH / 2, 
                top: HEIGHT / 2,
                originX: 'center',
                originY: 'center',
                lockUniScaling: true,
                scaleX: 0.25,
                scaleY: 0.25,
                lockScalingFlip: true
            });
            f.current.add(o);

            f.current.bringToFront(nikeText.current);
            f.current.bringToFront(userText.current);

            f.current.setActiveObject(o);
            f.current.renderAll();

        });
    };

    const setBackground = (url) => {
        fabric.Image.fromURL(url, (img) => {
            f.current.setBackgroundImage(img);
            f.current.renderAll();
/*
            var objs = f.current.getObjects();
            if (objs.length) {
                objs.forEach((e) => {
                    if (e && e.id === 'bg') {
                        console.log(img._element.currentSrc);
                        e._element.src = img._element.currentSrc;
                        f.current.renderAll();
                    }
                });
            }*/
        });
    }

    const reset = () => {
        f.current.clear();
        setDownloadMobile(null);
        setupCanvas();
        if(window.dataLayer) {
            window.dataLayer.push({
                'event': "COLLAGE_RESET"
            });
        }
    };

    const dataURLtoBlob = (dataurl) => {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }

    const download = () => {
        console.log("download");
        
        if(window.dataLayer) {
            window.dataLayer.push({
                'event': "COLLAGE_DOWNLOAD"
            });
        }

        f.current.discardActiveObject().renderAll();
        const img = canvas.current.toDataURL('image/jpeg', 1);

        if(detectMobile.isMobile()) {
            setDownloadMobile(img);
            //window.open(img, "_blank");
            
            // var blob = dataURLtoBlob(img);
           // FileSaver.saveAs(blob, "you-cant-stop-sport.jpg", {}, true);
        } else {
            var blob = dataURLtoBlob(img);
            FileSaver.saveAs(blob, "you-cant-stop-sport.jpg");
        }

        axios.get('/download').then((response) => {});
    };

    useEffect(() => {
        if(f.current === null) {
            fabric.Object.prototype.borderColor = '#FF00AA';
            fabric.Object.prototype.transparentCorners = false;
            fabric.Object.prototype.cornerColor = '#FF00AA';
            fabric.Object.prototype.cornerStyle = 'circle';
            fabric.Object.prototype.cornerSize = 30;
            //fabricjs-customise-controls-extension
            
            f.current = new fabric.Canvas(canvas.current, {
                backgroundColor: '#ffffff',
                height: HEIGHT,
                width: WIDTH,
                selection: false,
                defaultCursor: 'default',
                preserveObjectStacking: true
            });

            f.current.on('object:selected', () => {
                setDelete(true);
            });
            f.current.on('selection:cleared', () => {
                setDelete(false);
            });

            window.addEventListener('keydown', (e) => {
                if (e.which === 8) {
                    if (f.current.getActiveObject()) {
                        f.current.remove(f.current.getActiveObject());
                    }
                }
            })

            //f.current.item(0)['cornerSize'] = 25;
            //f.current.requestRenderAll();

            /*f.current.on('mouse:wheel', function(opt) {
                let currentSelection = f.current.getActiveObject();
                if(currentSelection) {
                    let delta = opt.e.deltaY;
                    let zoom = currentSelection.scaleX;
                    zoom = zoom + delta/200;
                    if (zoom > 5) zoom = 5;
                    if (zoom < 0.01) zoom = 0.01;
                    //canvas.setZoom(zoom);

                    currentSelection.set({
                        scaleX: zoom,
                        scaleY: zoom 
                    });

                    f.current.renderAll();
                }

                opt.e.preventDefault();
                opt.e.stopPropagation();
            });*/

            /*f.current.selectionColor = 'rgba(0,255,0,0.3)';
            f.current.selectionBorderColor = 'red';
            f.current.selectionLineWidth = 5;*/

            //this.__canvases.push(canvas);
    
            f.current.setWidth(WIDTH);
            f.current.setHeight(HEIGHT);
            
            setupCanvas();
        }
    }, []);

    
    return(
        <div className="create">
            {width > 768 ? <div className="create-stuff">
                <div className="create-title">Stickers <span>(Click to Choose)</span></div>
                <div className="create-stuff-stickers clearfix">
                    <button onClick={() => addImage(creatorSwoosh)}><img src={creatorSwoosh} alt="Swoosh" /></button>
                    <button onClick={() => addImage(creatorSerena)}><img src={creatorSerena} alt="Serena" /></button>
                    <button onClick={() => addImage(creatorRacquet)}><img src={creatorRacquet} alt="Tennis Racquet" /></button>
                    <button onClick={() => addImage(creatorYCSS)}><img src={creatorYCSS} alt="You Can't Stop Sport" /></button>
                    <button onClick={() => addImage(creatorBall)}><img src={creatorBall} alt="Tennis Ball" /></button>
                    <button onClick={() => addImage(creatorCourt)}><img src={creatorCourt} alt="Tennis Court" /></button>
                    
                </div>

                <div className="create-stuff-backgrounds">
                    <div className="create-title">Background</div>
                    <div className="create-stuff-backgrounds-buttons">
                        <button onClick={() => setBackground(bgImageWhite)}></button>
                        <button onClick={() => setBackground(bgImageClouds)}></button>
                        <button onClick={() => setBackground(bgImageGreen)}></button>
                    </div>
                </div>

                {/*<div className="create-stuff-hashtag">#youcantstopsisters</div>*/}
            </div> : null }
            <div className="create-canvas">
                <canvas ref={canvas} />
                {downloadMobile ? <img src={downloadMobile} className="final-img" alt="Final Image" /> : null}
                <div className="canvas-border"></div>
                {showDelete ? <button className="canvas-delete nike" onClick={deleteImage}>Delete</button> : null}

                {width > 768 ? (
                    <>
                    <button className="nike create-button1" onClick={reset}>Restart</button>
                    <button className="nike create-button2" onClick={download}>Download</button>
                    </>
                ) : null}
                    
            </div>

            {width <= 768 ? <div className="create-stuff">
                {downloadMobile ? (
                    <div className="download-instructions">
                        Hold down the image above to save to your camera roll
                        <div className="create-canvas">
                            <button className="nike create-button1" onClick={reset}>Restart</button>
                        </div>
                    </div> 
                ) : 
                    <>
                        <div className="create-title">Stickers <span>(Click to Choose)</span></div>
                        <div className="create-stuff-stickers clearfix">
                            <button onClick={() => addImage(creatorSwoosh)}><img src={creatorSwoosh} alt="Swoosh" /></button>
                            <button onClick={() => addImage(creatorSerena)}><img src={creatorSerena} alt="Serena" /></button>
                            <button onClick={() => addImage(creatorRacquet)}><img src={creatorRacquet} alt="Tennis Racquet" /></button>
                            <button onClick={() => addImage(creatorYCSS)}><img src={creatorYCSS} alt="You Can't Stop Sport" /></button>
                            <button onClick={() => addImage(creatorBall)}><img src={creatorBall} alt="Tennis Ball" /></button>
                            <button onClick={() => addImage(creatorCourt)}><img src={creatorCourt} alt="Tennis Court" /></button>
                        </div>

                        <div className="create-stuff-backgrounds">
                            <div className="create-title">Background</div>
                            <div className="create-stuff-backgrounds-buttons">
                                <button onClick={() => setBackground(bgImageWhite)}></button>
                                <button onClick={() => setBackground(bgImageClouds)}></button>
                                <button onClick={() => setBackground(bgImageGreen)}></button>
                            </div>
                        </div>

                        <div className="create-canvas">
                            <button className="nike create-button1" onClick={reset}>Restart</button>
                            <button className="nike create-button2" onClick={download}>Download</button>
                        </div>
                    </>
                }
            </div> : null }
        </div>
    );
}