import React, {useState, useRef, useEffect} from 'react';
import Draggable from 'react-draggable';
import { useWindowSize } from '@react-hook/window-size'
import {gsap} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Create from './Create';
import PlaySection from './PlaySection';
import Connect from './Connect';
import Game from './Game';
import TermsFooter from './TermsFooter';

import logo from './img/open-court.svg';
import signature from './img/signature.svg';
import downArrow from './img/down-arrow.svg';

window.country = 'US';

gsap.registerPlugin(ScrollToPlugin);

export default function Container({name, start}) {
    const [open, setOpen] = useState(false);
    const [width, height] = useWindowSize();
    const $content = useRef();

    const goto = (section) => {
        gsap.to($content.current, {scrollTo: '#'+section, delay: 0, duration: 0.25, ease: 'ease.out'})
    };

    const toggle = () => {
        if(window.dataLayer) {
            window.dataLayer.push({
                'event': "MENU_TOGGLE"
            });
        }
        
        setOpen(s => !s);
    };

    useEffect(() => setOpen(start), [start]);

    return (
        <Draggable
            handle=".handle"
            defaultPosition={{
                x: width < 728 ? width * 0.05 : width / 2 - 250, 
                y: 20
            }}
            bounds={{left: 0, top: 0, right: width - 500, bottom: height - 550}}
            disabled={width < 728 ? true : false}
        >
            <div className={open ? "container container-open" : "container"}>
                <div className="handle"><img src={logo} alt="Open Court" /></div>
                <button className={open ? "container-toggle container-toggle-open" : "container-toggle"} onClick={toggle}>
                    <div></div>
                    <div></div>
                </button>

                {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? <div className="content-menu">
                    <div className="content-menu-item"><button className="nike" onClick={() => goto('create')}>create</button></div>
                    <div className="content-menu-item"><button className="nike" onClick={() => goto('play')}>play</button></div>
                    <div className="content-menu-item"><button className="nike" onClick={() => goto('connect')}>connect</button></div>
                    <div className="content-menu-item"><button className="nike" onClick={() => goto('game')}>game</button></div>
                </div> : <div className="content-menu-outside"></div>}
            
                <div ref={$content} className="content">
                    <div className="content-section content-section-first content-section-flex">
                        <div className="content-section-header">
                            <div className="cursive">{name}</div>

                            {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? (
                                <p>Welcome to Open Court! Enjoy my special conversation with Actress & Producer, Storm Reid, about the power of sisterhood, life lessons learned through sport, my thoughts on health, wellness, and so much more. Don't forget to check out the game, custom graphic design, and lots of community resources!</p>
                            ) : (
                                <p>Welcome to Open Court! Enjoy my special conversation with Actress & Producer, Storm Reid, about the power of sisterhood, life lessons learned through sport, my thoughts on health, wellness, and so much more. </p>  
                            )}

                            <img src={signature} alt="Serena Williams" />
                        </div>

                        {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? <a href="#" onClick={() => goto('create')} className="down-arrow"><img src={downArrow} alt="Scroll Down" /></a> : null} 
                    </div>

                    {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? <div id="create" className="content-section">
                        <div className="content-section-header">
                            <h1 className="nike">Create Your Own Graphic</h1>
                            <p>Create, download, and share your own "You Can't Stop Sisters" graphic. Choose from a selection of 3D stickers and backgrounds to customize your design. Share it on social with the hashtag #youcantstopsisters.</p>
                        </div>

                        <Create name={name} />
                    </div> : null}

                    {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? <div id="play" className="content-section">
                        <div className="content-section-header">
                            <h1 className="nike">Get Out And Play</h1>
                            <p>Below are some ways in which you and your family can stay active through sport and in life.</p>
                            <p style={{marginBottom: 0}}>Click on the boxes below to explore local city resources, Nike apps, and product to gear-up.</p>
                        </div>

                        <PlaySection />
                    </div> : null}

                    {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? <div id="connect" className="content-section">
                        <div className="content-section-header">
                            <h1 className="nike">CONNECT WITH LOCAL COMMUNITY PARTNERS</h1>
                            <p>Learn more about local non-profits whose goal is to make sure girls across North America have the opportunity and resources to stay active through sport.</p>
                        </div>

                        <Connect />
                    </div> : null}

                    {window.country === 'US' || window.country === 'CA' || window.country === 'MX' ? <div id="game" className="content-section">
                        <div className="content-section-header">
                            <h1 className="nike">TENNIS SERVE CHALLENGE</h1>
                            <p>Serve the ball when the timer hits 3.0 seconds for a chance to receive a limited edition "You Can’t Stop Sisters" t-shirt. Tap the ball to start and stop your serve.<br />
                            <small>While supplies last. T-shirts limited to first 1,000 winners in the US only. Requested sizing not guaranteed. 1 per Nike member.</small></p>
                        </div>

                        <Game />
                    </div> : null}

                    <TermsFooter />

                </div>
            </div>
        </Draggable>
    );
}