import React from 'react';
import Cookies from './Cookies';
import logo from './img/open-court.svg';

export default function Intro({ placeholder = false, vip = false }) {
    return(
        <>
            <div className="intro">
                {placeholder ? (
                    <div className="intro-inner">
                        <div className="logo"><img src={logo} alt="Open Court" /></div>
                        <div className="placeholder">
                            Open Court with Serena Williams may have come and gone, but <a href="https://www.nike.com/nike-app ">download the Nike App</a> today for more exclusive content and access to the very best Nike products, inspiration and community. Remember to turn on your push notifications so you don’t miss a thing.
                        </div>
                    </div>
                ) : 
                    (
                        <div className="intro-inner">
                            <div className="logo"><img src={logo} alt="Open Court" /></div>
                            <Cookies />
                            <button onClick={() => window.location.href='/redirect'}>Login with your Nike Member<br />Username & Password</button>
                        </div>
                    )
                }
            </div>
        </>
    );
}