import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import App from './App';
import Intro from './Intro'
import ThreeD from './ThreeDTop'
import Create from './Create';

export default function R() {
  return (
    <Router>
        <Switch>
          <Route path="/earlyaccess92681">
            <App />
          </Route>
          <Route path="/webgl">
            <ThreeD />
          </Route>
          <Route path="/">
            <>
              <Intro placeholder />
              <ThreeD />
            </>
          </Route>
          
        </Switch>
    </Router>
  );
}
