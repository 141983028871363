import React, {useState, useRef, useEffect} from 'react';
import {useTrail, animated} from 'react-spring'
import {gsap} from 'gsap';
import axios from 'axios';
import gameRacquet from './img/game-racquet.png';

const MAX = 8000;

export default function Game() {
    const gameRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [over, setOver] = useState(false);
    const [gameState, setGameState] = useState('');
    const [isDown, setDown] = useState(false);
    const [won, setWon] = useState(false);
    const [lost, setLost] = useState(false);
    const interval = useRef();
    const timeout = useRef();
    const timeline = useRef();
    const ball = useRef();

    const [count, setCount] = useState(false);

    const fast = { tension: 200, friction: 40 };
    const [trail, set] = useTrail(1, () => ({ xy: [0, 0], config: fast }))
    const trans = (x, y) => `translate3d(${x}px,${y}px,0)`

    const start = () => {
        setIsPlaying(true);
        setOver(false);
        setSeconds(0);
        setGameState('game-playing');
        interval.current = setInterval(() => {
            setSeconds(s => s + 10);
        }, 10);

        serve();

        if(window.dataLayer) {
            window.dataLayer.push({
                'event': "GAME_PLAY"
            });
        }
    };

    const stop = () => {
        setIsPlaying(false);
        clearInterval(interval.current);
        clearTimeout(timeout);
        // check if win
    };

    const reset = () => {
        setSeconds(0);
        setIsPlaying(false);
        setOver(false);
        setGameState('');
        setWon(false);
        setLost(false);
        clearInterval(interval);
        clearTimeout(timeout);
    };

    const down = () => {setDown(true)};
    const up = () => {setDown(false)};

    const claim = () => {
        console.log('claim');
    };

    const serve = () => {
        timeline.current = gsap.timeline({ onComplete: () => {
            setGameState('game-soon');
            setLost(true);
            setWon(false);
            clearInterval(interval.current);
        }});
        timeline.current.to(ball.current, {translateY: -400, scale: 1, duration: 2, ease: 'none'});
        timeline.current.to(ball.current, {translateY: -50, scale: 1, duration: 1, ease: 'none'});
        timeline.current.to(ball.current, {translateY: 350, scale: 1, duration: 1, ease: 'none'});
    }

    const miss = () => {
        timeline.current.pause();
        timeline.current = gsap.timeline({ onComplete: () => {
            setGameState('game-soon');
            setLost(true);
            setWon(false);
            clearInterval(interval.current);
        }});
        timeline.current.to(ball.current, {translateY: 350, scale: 0, duration: 1, ease: 'none'});
    };

    const tooSoon = () => {
        //timeline.current.pause();
        //timeline.current = gsap.timeline({ onComplete: () => {}});
        //timeline.current.to(ball.current, {translateY: 300, scale: 0, duration: 1});
    };

    const perfect = () => {
        timeline.current.pause();
        timeline.current = gsap.timeline({ onComplete: () => {
            setGameState('game-won');
            setWon(true);
        }});
        timeline.current.to(ball.current, {translateY: 0, scale: 0, duration: 1, ease: 'none'});

        axios.get('/winner').then((response) => {});
    };

    const toggle = () => {
        if(isPlaying) {
            clearInterval(interval.current);
            if(seconds < 3300 && seconds > 3000) {
                perfect();
            }

            if(seconds < 3000) {
                miss();
            }

            if(seconds >= 3300) {
                miss();
            }

            stop();
        } else {
            reset();
            start();
        }
    };

    useEffect(() => {
        if(seconds > 3000 && !over) {
            setOver(true);
        }

        if(seconds > MAX) {
            clearInterval(interval.current);
            setLost(true);
            setWon(false);
        }
    }, [seconds]);

    useEffect(() => {
        axios.get('/game')
            .then((response) => {
                // handle success
                console.log(response.data);
                setCount(response.data.game);
            });
    }, []);

    return(
        <div ref={gameRef} className="content-section-wide game" onMouseMove={e => { set({ xy: [e.clientX - gameRef.current.getBoundingClientRect().left, e.clientY - gameRef.current.getBoundingClientRect().top] })}}>
            <button className={over ? "game-board game-board-over nike" : "game-board nike"} onClick={toggle} onMouseDown={down} onMouseUp={up}>
                {!won ? (
                    <>
                        <span>{(seconds / 1000).toFixed(2)}</span>
                        <div ref={ball} className={"game-ball "}><div className="game-ball-inner"></div></div>

                        {trail.map((props, index) => (
                            <animated.div key={index} className={isDown ? "game-racquet game-racquet-down" : "game-racquet"} style={{ transform: props.xy.interpolate(trans) }}><img src={gameRacquet} alt="Racquet" /></animated.div>
                        ))}
                    </>
                ): null}
            </button>

            {won ? <div className="game-board game-screen-won">
                {count ? (
                    <div>
                        <h1 className="nike">ACE, nice serve!</h1>
                        <p>Click here to claim your limited edition “You Can’t Stop Sisters” t-shirt.</p>
                        <button className="nike" onClick={() => { window.open('https://www.nike.com/events-registration/event?id=138137', '_blank')}}>Claim your t-shirt</button>
                    </div>
                ) : (
                    <div>
                        <h1 className="nike">Thank you for playing!</h1>
                        <p>All t-shirts have been claimed. Continue to practice your serve and check out the new <a href="https://www.nike.com/w/challenge-court-collection-49a0l" target="_blank">Nike Court collection</a>.</p>
                        <button className="nike" onClick={reset}>Play Again</button>
                    </div>
                )}
            </div> : null }

            {lost ? <div className="game-board game-screen-won">
                <div>
                    <h1 className="nike">Fault! Try again!</h1>

                    <button className="nike" onClick={reset}>Try your serve again</button>
                </div>
            </div> : null }

            <button className="game-restart nike" onClick={reset}>Restart</button>
        </div>
    )
}